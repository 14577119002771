export const navLinks = [
  {
    label: "Products",
    links: [
      {
        label: "[AI] Platform",
        link: "/ai-platform",
      },
      {
        label: "Data Assistant",
        link: "/data-assistant",
      },
      {
        label: "PLLM",
        link: "/pllm",
      },
    ],
  },
  {
    label: "Solutions",
    links: [
      {
        label: "Retail & E-commerce",
        link: "/retail",
      },
      {
        label: "Fintech & Finance",
        link: "/fintech",
      },
      {
        label: "Marketing & Advertising",
        link: "/marketing",
      },
    ],
  },
  {
    label: "Resources",
    links: [
      {
        label: "Digital Analytics Support",
        link: "/digital-analytics-support",
      },
      {
        label: "Digital Analytics Training",
        link: "/digital-analytics-training",
      },
      // {
      //   label: "Guides",
      //   link: "/guides",
      // },
      {
        label: "Blog",
        link: "/blog",
      },
      // {
      //   label: "Events",
      //   link: "/events",
      // },
    ],
  },
];

export const footerLinks = [
  {
    label: "PRODUCT",
    links: [
      {
        label: "[AI] Platform",
        link: "/ai-platform",
      },
      {
        label: "Data Assistant",
        link: "/data-assistant",
      },
      {
        label: "PLLM",
        link: "/pllm",
      },
      {
        label: "Request a Demo",
        link: "/demo",
      },
    ],
  },
  {
    label: "COMPANY",
    links: [
      {
        label: "About us",
        link: "/company",
      },
      {
        label: "Contact Us",
        link: "/company#contact-us",
      },
      {
        label: "Careers",
        link: "/company#careers",
      },
      {
        label: "Faqs",
        link: "/#faqs",
      },
    ],
  },
  {
    label: "LEGAL",
    links: [
      {
        label: "Legal information",
        link: "/privacy",
      },
      {
        label: "Privacy",
        link: "/privacy",
      },
      {
        label: "Cookies",
        link: "/",
      },
      {
        label: "",
        link: "/",
      },
    ],
  },
  {
    label: "CONTACT",
    links: [
      {
        label: "info@analyticsintelligence.com",
        link: "mailto:info@analytics intelligence.com",
      },
      {
        label: "sales@analyticsintelligence.com",
        link: "mailto:sales@analytics intelligence.com",
      },
      {
        label: "support@analyticsintelligence.com",
        link: "mailto:support@analyticsintelligence.com",
      },
      {
        label: "careers@analyticsintelligence.com",
        link: "mailto:careers@analyticsintelligence.com",
      },
    ],
  },
];
